<template>
  <div @mousemove="mousemove">
    <v-data-table
      :headers="headers"
      :items="dbitems"
      item-key="Id"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
      disable-pagination
      fixed-header
      must-sort
      sort-by="created"
      :custom-sort="customSort"
      :sort-desc="false"
      :class="!alternatelayout ? 'mksoutlined' : 'elevation-3'"
      style="overflow: auto; max-height: 345px"
      :outlined="!alternatelayout"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          :class="className + ' ' + (!alternatelayout ? 'mksborderradius' : '')"
        >
          <v-toolbar-title>{{ tabletitle }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="mx-2"
                fab
                :dark="$vuetify.theme.dark"
                small
                v-bind="attrs"
                v-on="on"
                @click="openFileSelector"
                v-show="itemrights.write"
              >
                <v-icon>attachment</v-icon>
              </v-btn>
              <input
                ref="FileSelector"
                type="file"
                multiple
                style="display: none"
                @change="prepSetAttachmentTypes"
              />
            </template>
            <span>Attach File(s)</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <template v-slot:item="props">
        <tr
          :class="className"
          :style="
            'background-color: ' +
            (props.item['ID_Attachment_Assignment'] === null
              ? $vuetify.theme.dark
                ? '#c4aa78'
                : '#fff0d4'
              : '') +
            ';'
          "
          :itemid="props.item[identifier]"
        >
          <template v-for="(header, index) of headers">
            <td v-if="itemrights.write && index === 0" :key="index">
              <v-icon
                v-if="editquery.id > 0 && !props.item['otherSource']"
                small
                @click="prepdeletedata(props.item)"
                >delete</v-icon
              >
              <v-checkbox
                v-else-if="editquery.id < 0 && !props.item['otherSource']"
                v-model="copyfiles"
                :value="props.item.ID_Attachment_Assignment"
              ></v-checkbox>
            </td>
            <td
              v-else-if="header.value === 'Filesize'"
              :prevId="props.item['ID_Attachment_Assignment']"
              class="alignright"
              @click="openfile(props.item)"
              :key="index"
              v-html="$niceBytes(props.item[header.value])"
            ></td>
            <td
              v-else-if="
                header.value === 'Rotate' &&
                editquery.id > 0 &&
                props.item['ID_Attachment_Assignment'] > 0 &&
                (props.item['Filename'].toLowerCase().indexOf('.jpg') > -1 ||
                  props.item['Filename'].toLowerCase().indexOf('.jpeg') > -1)
              "
              :prevId="props.item['ID_Attachment_Assignment']"
              :key="index"
            >
              <v-tooltip bottom open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-icon
                    ref="rotateLeftBtn"
                    :dark="$vuetify.theme.dark"
                    bottom
                    center
                    v-on="on"
                    @click="
                      rotate($event, props.item.ID_Attachment_Assignment, -90)
                    "
                    >rotate_left</v-icon
                  >
                </template>
                <span
                  >Rotate Image 90° left<br />(The quality decreases
                  slightly.)</span
                > </v-tooltip
              ><v-tooltip bottom open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-icon
                    ref="rotateRightBtn"
                    :dark="$vuetify.theme.dark"
                    bottom
                    center
                    v-on="on"
                    @click="
                      rotate($event, props.item.ID_Attachment_Assignment, 90)
                    "
                    >rotate_right</v-icon
                  >
                </template>
                <span
                  >Rotate Image 90° right<br />(The quality decreases
                  slightly.)</span
                >
              </v-tooltip>
            </td>
            <td v-else-if="header.value === 'Notes'" :key="index">
              <v-textarea auto-grow rows="1" v-model="props.item[header.value]">
                <template slot="append">
                  <v-icon small style="opacity: 0.5">edit</v-icon>
                </template></v-textarea
              >
            </td>
            <td
              v-else
              @click="openfile(props.item)"
              :key="index"
              v-html="
                (header.value === 'Filename' ||
                  header.value === 'Attachmenttype') &&
                props.item[header.value]
                  ? props.item[header.value].replace(/_/g, '_<wbr>')
                  : props.item[header.value]
              "
              :prevId="props.item['ID_Attachment_Assignment']"
            ></td>
          </template>
        </tr>
      </template>
      <template v-slot:body.append="props">
        <tr>
          <td
            class="onhover"
            @click="openFileSelector"
            :colspan="props.headers.length"
            style="text-align: right; padding-right: 16px; padding-bottom: 4px;"
          >
            <v-btn icon class="mx-2" fab small>
              <v-icon>attachment</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template slot="no-data"> </template>
    </v-data-table>

    <v-dialog
      v-model="showconfirmdelete"
      content-class="v-dialog-mks"
      @close="
        delpreviewsrc = '';
        deltop = 0;
      "
    >
      <v-card>
        <v-card-title>
          <span class="mksheadline"
            >Delete '{{ this.filetodeleteheading }}' ?</span
          >
        </v-card-title>
        <v-card-text class="text--primary">
          <div class="imgfade">
            <div
              style="
                overflow: hidden;
                height: 350px;
                width: 100%;
                box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
              "
            >
              <img :src="delpreviewsrc" class="delimage" ref="delimage" />
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" class="mr-4" @click="deleteAttachment">
            <v-icon left>delete</v-icon>delete Attachment
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="warning" class="mr-4" @click="showconfirmdelete = false"
            >cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showSetAttachmentType"
      persistent
      content-class="v-dialog-mks"
    >
      <v-card>
        <v-card-title>
          <span class="mksheadline">{{
            selectableAttachmentTypes.length > 0
              ? "Set Attachment Type(s)"
              : "Check Upload Files"
          }}</span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="showSetAttachmentType = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="text--primary">
          <v-data-table
            :headers="newAttachmentHeadersFiltered"
            :items="newAttachmentItems"
            item-key="name"
            class="elevation-1"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item="props">
              <tr class="text-start">
                <td v-if="selectableAttachmentTypes.length > 0">
                  <v-autocomplete
                    v-model="props.item['attype']"
                    :ref="'attt_' + props.item['name']"
                    :items="selectableAttachmentTypes"
                    hide-no-data
                    hide-selected
                    item-text="Attachmenttype"
                    item-value="ID_Attachmenttype"
                    full-width
                    auto-select-first
                    autofocus
                    dense
                    clearable
                  ></v-autocomplete>
                </td>
                <td>{{ props.item["name"] }}</td>
                <td>
                  <v-textarea
                    v-model="props.item['notes']"
                    rows="1"
                  ></v-textarea>
                </td>
                <td class="alignright">{{ props.item["size"] }}</td>
                <td class="aligncenter">{{ props.item["dimensions"] }}</td>
                <td>
                  <v-checkbox
                    v-if="props.item['isLargeImage']"
                    v-model="props.item['scaledown']"
                    :label="props.item['newdimensions']"
                  ></v-checkbox>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" class="mr-4" @click="setAttachmentInfo"
            >add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}

.v-data-table /deep/ .v-data-table__empty-wrapper {
  display: none;
}

td.alignright {
  text-align: right;
}

td.aligncenter {
  text-align: center;
}

td.onhover:hover {
  background-color: #eee;
  cursor: pointer;
}

/* .theme--light.v-datatable .v-datatable__actions {
  position: fixed;
  bottom: 0;
  width: 100%;
} */

tr.text-start {
  cursor: pointer;
}

div.previewHtml {
  position: absolute;
  top: 2em;
  left: 9em;
  padding: 2em;
  width: 50%;
  height: 90%;
  z-index: 7500;
  overflow: auto;
  background-color: white;
  color: black;

  -webkit-box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
  box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
}

img.previewImage {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 7500;

  -webkit-box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
  box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
}

img.delimage {
  position: relative;
  top: 0px;
  width: 750px;
}

div.imgfade {
  overflow: hidden;
  padding: 30px;
  height: 380px;
}

div.imgfade::after {
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, transparent 0%, white 100%);
  margin-top: -150px;
  height: 150px;
  width: 100%;
  content: "";
}

.mksborderradius {
  border-radius: 4px !important;
}
</style>

<script>
var newFilesCheckCounter = 0;

export default {
  name: "mksfiles",

  props: {
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    AttachmentTypes: {
      type: Array,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
    className: {
      type: String,
      required: true,
      default: "",
    },
    refreshfile: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    filesToUpload: [],
    dialog: false,
    details: false,
    headers: [{ text: " ", value: " " }],
    receivedHeaders: [],
    dbitems: [],
    itemrights: {},
    currentQueryInfo: {},
    currentQueryLinksInfo: {},
    identifier: "",
    editlnk: {},
    itemForNotes: "",
    defaultItem: {},
    loading: false,
    previewloading: false,
    errors: [],
    tabletitle: "",
    container: null,
    containerparent: null,
    myerror: "",
    showconfirmdelete: false,
    filetodelete: null,
    filetodeleteheading: "",
    delpreviewsrc: "",
    lastItemid: 0,
    clientX: 0,
    clientY: 0,
    scrollInterval: null,
    scrollTimeout: null,
    deltop: 0,
    Attachment: null,
    newAttachments: [],
    showSetAttachmentType: false,
    newAttachmentHeaders: [
      { text: "Type", value: "type" },
      { text: "File", value: "name" },
      { text: "Notes", value: "notes" },
      { text: "Size", value: "size" },
      { text: "Dimensions", value: "dimensions" },
      { text: "Scale down", value: "scale", sortable: false },
    ],
    newAttachmentItems: [],
    copyfiles: [],
    htmlprevcontent: "",
    previewtype: null,
    shadowCopy: [],
    field: { d: false },
    rotateTrg: null,
  }),

  mounted() {
    this.container = this.$el.closest
      ? this.$el.closest("div.container")
      : this.$el.parentElement.closest("div.container");
    this.containerparent = this.container.parentElement;

    // this.getdata();
  },
  computed: {
    owl() {
      return require("@/assets/owl2.png");
    },
    id() {
      return this.editquery.id;
    },
    getcopyfiles() {
      return this.copyfiles;
    },

    selectableAttachmentTypes() {
      return this.AttachmentTypes.filter((at) => at.ID_Attachmenttype !== null);
    },

    newAttachmentHeadersFiltered() {
      if (this.selectableAttachmentTypes.length > 0) {
        return this.newAttachmentHeaders;
      } else {
        return this.newAttachmentHeaders.filter((h) => h.value !== "type");
      }
    },

    changedNotes() {
      var notes = [];
      var orig = null;
      for (var i = 0; i < this.dbitems.length; i++) {
        orig = this.shadowCopy.find(
          (s) =>
            s.ID_Attachment_Assignment ==
            this.dbitems[i].ID_Attachment_Assignment
        );
        if (orig && orig.Notes !== this.dbitems[i].Notes) {
          notes.push({
            id: this.dbitems[i].ID_Attachment_Assignment,
            notes: this.dbitems[i].Notes,
          });
        }
      }
      return notes;
    },

    returnValue: {
      get() {
        const fileNotes = this.dbitems.map((obj) => {
          return {
            attype: obj.ID_Attachmenttype,
            name: obj.Filename,
            notes: obj.Notes,
            scaledown: obj.scaledown,
          };
        });

        return {
          n: this.field.n,
          v: this.newAttachments.length,
          files: this.filesToUpload, //[...this.newAttachments]
          FileNotes: fileNotes,
          ChangedFileNotes: this.changedNotes,
        };
      },
    },

    // returnValue: {
    //   get() {
    //     // var notes = []; var orig = null;
    //     // for (var i = 0; i < this.dbitems.length; i++) {
    //     //   orig = this.shadowCopy.find(s => s.ID_Attachment_Assignment == this.dbitems[i].ID_Attachment_Assignment)
    //     //   if (orig && orig.Notes !== this.dbitems[i].Notes) {
    //     //     notes.push(
    //     //       {
    //     //         id: this.dbitems[i].ID_Attachment_Assignment,
    //     //         notes: this.dbitems[i].Notes
    //     //       }
    //     //     );
    //     //   }
    //     // }

    //     return {
    //       n: 'file notes',
    //       v: this.changedNotes,
    //     };
    //   },
    // },

    changed: {
      get() {
        return this.changedNotes.length > 0 || this.filesToUpload.length > 0;
      },
    },
  },
  watch: {
    id: function (n) {
      this.tabletitle =
        n < 0
          ? "Please select all files to be copied to the new element:"
          : "Files";
      this.resetCopyfiles();
    },
    refreshfile: {
      handler() {
        this.getdata();
      },
    },
  },
  methods: {
    resetCopyfiles() {
      this.copyfiles = this.dbitems.map((i) => i.ID_Attachment_Assignment);
    },
    customSort(items, index, isDesc) {
      var sortBy = index[0] === "created" ? "created_sort" : index[0];
      items.sort((a, b) => {
        if (isDesc != "false") {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return b[sortBy] < a[sortBy] ? -1 : 1;
        }
      });
      return items;
    },
    getdata() {
      this.$http
        .post("api/getAttachedFiles", {
          query: this.editquery,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            if (response.data === "NO ATTACHMENT TABLE") {
              // this.visible = false;
              return;
            } else {
              throw "malformatted JSON Data";
            }
          }

          if (response.data.error) {
            this.tabletitle = "Error loading Data";
            this.myerror = response.data.error;
            this.items = null;
            this.loading = false;
          } else {
            this.dbitems = response.data.recordsets[0].data;
            this.itemrights = response.data.rights;
            this.tabletitle = "Files (" + this.dbitems.length + ")";

            if (this.dbitems.length == 0) {
              this.items = null;
            }

            this.shadowCopy = JSON.parse(JSON.stringify(this.dbitems));

            this.totalitems = parseInt(response.data.recordsets[1]);

            this.receivedHeaders = [];
            var tmpObject = null;
            for (var cnt = 0; cnt < response.data.recordsets[2].length; cnt++) {
              if (
                !(
                  this.AttachmentTypes.length === 0 &&
                  response.data.recordsets[2][cnt] === "Attachmenttype"
                )
              ) {
                tmpObject = {
                  text: response.data.recordsets[2][cnt].replace("_", " "),
                  value: response.data.recordsets[2][cnt],
                };
                if (
                  tmpObject.value.substr(0, 3).toLowerCase() != "id_" &&
                  tmpObject.value !== "otherSource" &&
                  tmpObject.value !== "_bgcolor" &&
                  tmpObject.value !== "created_sort"
                ) {
                  this.receivedHeaders.push(tmpObject);
                }
              }
            }
            // Add Action Column
            if (this.itemrights.write) {
              if (this.editquery.id != 0) {
                tmpObject = {
                  text: this.editquery.id > 0 ? "Action" : "Copy",
                  value: "action",
                  sortable: false,
                };
                this.receivedHeaders.unshift(tmpObject);
                this.receivedHeaders[0].fixed = true;
              }

              tmpObject = {
                text: "",
                value: "Rotate",
                sortable: false,
              };
              this.receivedHeaders.splice(4, 0, tmpObject);
            }

            this.headers = this.receivedHeaders;

            this.identifier = response.data.recordsets[5];

            this.loading = false;
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.errors.push(e);
          this.loading = false;
        });
    },

    animateScroll() {
      if (this.scrollInterval) {
        window.clearInterval(this.scrollInterval);
      }
      if (this.scrollTimeout) {
        window.clearTimeout(this.scrollTimeout);
      }

      var tempthis = this;

      var fanimateScroll = function () {
        if (tempthis.$refs.delimage) {
          tempthis.deltop -= 2;
          tempthis.$refs.delimage.style.top = tempthis.deltop + "px";
        }
      };
      this.scrollInterval = window.setInterval(fanimateScroll, 50);
      this.scrollTimeout = window.setTimeout(function () {
        window.clearInterval(tempthis.scrollInterval);
      }, 10000);
    },
    rotate(event, itemid, degree) {
      this.rotateTrg = document
        .elementFromPoint(event.clientX, event.clientY)
        .closest("[prevId]");
      sessionStorage.removeItem("prev" + itemid);
      this.openfile(itemid, true, 300, degree);
    },

    openfile(itemid, asPreview = false, MaxPreviewDimension = 300, degree = 0) {
      if (itemid.ID_Attachment_Assignment === null) {
        return;
      }
      var winName = "FilePresentation";
      var winURL = "api/getAttachedFileContentGet";

      var params =
        this.editquery.id_query +
        "." +
        this.editquery.id_link +
        "." +
        this.editquery.id +
        "." +
        this.editquery.identifier +
        "." +
        degree +
        "." +
        (itemid.ID_Attachment_Assignment
          ? itemid.ID_Attachment_Assignment
          : itemid) +
        (asPreview ? ".1." + MaxPreviewDimension : "");

      if (asPreview) {
        if (!this.previewloading) {
          this.previewloading = true;
          this.previewsrc = null;
          
          this.$http
            .get(winURL + "?att=" + params)
            .then((response) => {
              if (response.data.error) {
                this.myerror = response.data.error;
              } else if (response.data !== "") {
                if (MaxPreviewDimension === 750) {
                  this.delpreviewsrc =
                    "data:" +
                    response.headers["content-type"] +
                    ";base64," +
                    response.data;
                  this.animateScroll();
                } else {
                  if (this.rotateTrg) {
                    var params = {
                      editquery: this.editquery,
                      ID_Attachment_Assignment: parseInt(
                        this.rotateTrg.getAttribute("prevId")
                      ),
                    };
                    this.$showPreview(params);
                  }
                }
              } else {
                this.delpreviewsrc = "";
              }
            })
            .catch(() => {
              this.previewtype = "img";
              this.delpreviewsrc = "";
              this.previewsrc = this.owl;
            })
            .finally(() => {
              this.previewloading = false;
            });
        }
      } else {
        window.open(winURL + "?att=" + params, winName);
      }
    },

    prepdeletedata(item) {
      if (item.ID_Attachment_Assignment === null) {
        var fnd = this.filesToUpload.findIndex(
          (fi) => fi.name === item.Filename
        );
        if (fnd > -1) {
          this.filesToUpload.splice(fnd, 1);
        }
        fnd = this.dbitems.findIndex((fi) => fi.Filename === item.Filename);
        if (fnd > -1) {
          this.dbitems.splice(fnd, 1);
        }
      } else {
        this.deltop = 0;
        this.filetodelete = item;
        this.filetodeleteheading = item.Filename;
        this.delpreviewsrc = this.previewsrc;
        this.showconfirmdelete = true;
        this.openfile(item, true, 750);
      }
    },

    mousemove(evt) {
      var trg = document
        .elementFromPoint(evt.clientX, evt.clientY)
        .closest("[prevId]");
      if (trg) {
        var params = {
          editquery: this.editquery,
          ID_Attachment_Assignment: parseInt(trg.getAttribute("prevId")),
        };
        this.$showPreview(params);
      }
    },

    deleteAttachment() {
      this.$http
        .post("api/deleteAttachedFile", {
          query: this.editquery,
          file: this.filetodelete.ID_Attachment_Assignment,
        })
        .then(() => {
          this.$showMessage({
            content: "attachment successfully removed",
            color: "success",
          });
          this.showconfirmdelete = false;
          this.show = false;
          this.getdata();
          this.$emit("filesChanged");
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        });
    },

    openFileSelector() {
      this.$refs.FileSelector.click();
    },

    // ensureAttachmentTypes(e) {
    //   if (!this.itemrights.write) {
    //     return;
    //   }

    //   this.newAttachments = e.dataTransfer
    //     ? e.dataTransfer.files
    //     : this.$refs.FileSelector.files;
    //   if (this.AttachmentTypes.length === 0) {
    //     this.$http
    //       .post("api/getAttachmentTypes", {
    //         query: this.editquery,
    //       })
    //       .then((result) => {
    //         this.AttachmentTypes = result.data;

    //         this.prepSetAttachmentTypes();
    //       })
    //       .catch((e) => {
    //         var message =
    //           e.response && e.response.data
    //             ? e.response.data
    //             : e.message
    //             ? e.message
    //             : e;
    //         this.$showMessage({ content: message, color: "error" });
    //       });
    //   } else {
    //     this.prepSetAttachmentTypes();
    //   }

    //   //this.$showMessage({ content: newAttachments.length, color: "info" });
    // },

    prepSetAttachmentTypes(e) {
      if (!this.itemrights.write) {
        return;
      }

      this.newAttachments = e.dataTransfer
        ? e.dataTransfer.files
        : this.$refs.FileSelector.files;

      for (var cnt = 0; cnt < this.newAttachments.length; cnt++) {
        this.filesToUpload.push(this.newAttachments[cnt]);
      }

      this.newAttachmentItems.length = 0;

      var reader = new FileReader();
      var tempthis = this;
      reader.onerror = function () {
        tempthis.newAttachmentItems.push({
          attype: 0,
          name: tempthis.newAttachments[newFilesCheckCounter].name,
          size: tempthis.$niceBytes(
            tempthis.newAttachments[newFilesCheckCounter].size
          ),
          scaledown: false,
          isLargeImage: false,
        });
        if (newFilesCheckCounter < tempthis.newAttachments.length) {
          reader.readAsDataURL(tempthis.newAttachments[newFilesCheckCounter++]);
        } else {
          tempthis.showSetAttachmentType = true;
        }
      };

      reader.onload = function () {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = reader.result;

        //Validate the File Height and Width.
        image.onerror = function () {
          tempthis.newAttachmentItems.push({
            attype: 0,
            name: tempthis.newAttachments[newFilesCheckCounter].name,
            size: tempthis.$niceBytes(
              tempthis.newAttachments[newFilesCheckCounter].size
            ),
            scaledown: false,
            isLargeImage: false,
          });
          newFilesCheckCounter++;
          if (newFilesCheckCounter < tempthis.newAttachments.length) {
            reader.readAsDataURL(tempthis.newAttachments[newFilesCheckCounter]);
          } else {
            tempthis.showSetAttachmentType = true;
          }
        };

        image.onload = function () {
          var width = this.width;
          var height = this.height;
          var dimensions = width + "x" + height;
          var newdimensions = dimensions;
          var isLargeImage;

          if (width > 1920 || height > 1080) {
            var factorW, factorH, factor;
            factorW = 1920.0 / width;
            factorH = 1080.0 / height;
            factor = Math.min(factorW, factorH);

            newdimensions =
              Math.floor(width * factor) + "x" + Math.floor(height * factor);
          }

          isLargeImage =
            width > 1920 ||
            height > 1080 ||
            tempthis.newAttachments[newFilesCheckCounter].size > 150000;

          tempthis.newAttachmentItems.push({
            attype: 0,
            name: tempthis.newAttachments[newFilesCheckCounter].name,
            size: tempthis.$niceBytes(
              tempthis.newAttachments[newFilesCheckCounter].size
            ),
            isLargeImage: isLargeImage,
            scaledown: isLargeImage,
            dimensions: dimensions,
            newdimensions: newdimensions,
          });
          newFilesCheckCounter++;

          if (newFilesCheckCounter < tempthis.newAttachments.length) {
            reader.readAsDataURL(tempthis.newAttachments[newFilesCheckCounter]);
          } else {
            tempthis.showSetAttachmentType = true;
          }
        };
      };

      if (this.newAttachments.length > 0) {
        newFilesCheckCounter = 0;
        reader.readAsDataURL(this.newAttachments[newFilesCheckCounter]);
      }
    },

    setAttachmentInfo() {
      var file = null;
      var fileInfo = null;
      var attypename = null;
      // var now = new Date();
      // var nowstring =
      //   String(now.getDate()).padStart(2, "0") +
      //   "." +
      //   String(now.getMonth() + 1).padStart(2, "0") +
      //   "." +
      //   now.getFullYear() +
      //   " " +
      //   String(now.getHours()).padStart(2, "0") +
      //   ":" +
      //   String(now.getMinutes()).padStart(2, "0");

      for (var acnt = 0; acnt < this.newAttachments.length; acnt++) {
        file = this.newAttachments[acnt];
        fileInfo = this.newAttachmentItems.find((e) => e.name === file.name);

        attypename = this.selectableAttachmentTypes.find(
          (s) => s.ID_Attachmenttype === fileInfo.attype
        );

        this.dbitems.push({
          created: "not saved yet!", //nowstring,
          ID_Attachmenttype: fileInfo.attype,
          Attachmenttype:
            attypename && attypename.Attachmenttype
              ? attypename.Attachmenttype
              : null,
          Filename: file.name,
          Creator: null,
          Notes: fileInfo.notes,
          ID_Attachment_Assignment: null,
          created_sort: null,
          Filesize: file.size,
          otherSource: false,
          scaledown: fileInfo.scaledown,
        });
      }
      this.showSetAttachmentType = false;
      this.$refs.FileSelector.value = "";
      this.$emit("filesChanged");
    },
    saveAttachments(formData) {
      this.$http
        .post("api/saveChanges", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.SavedInfoText = "values saved successfully";
          this.show = false;
          this.getdata();
          this.$emit("filesChanged");
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        });

      this.showSetAttachmentType = false;
      this.$refs.FileSelector.value = "";
    },
  },
};
</script>
