<template>
  <div v-if="visible">
    <v-data-table
      :dark="$vuetify.theme.dark"
      :headers="headers"
      :items="remarks"
      item-key="Id"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
      fixed-header
      :height="tableHeight"
      must-sort
      sort-by="created"
      :sort-desc="false"
      :class="!alternatelayout ? 'mksoutlined' : 'elevation-3'"
      style="overflow: auto; max-height: 345px"
      :outlined="!alternatelayout"
      :style="
        bgcolor + ';' + (!print ? 'overflow: auto; max-height: 345px;' : '')
      "
    >
      <template v-slot:no-data v-if="!print"
        >
        <div
          v-if="remarks.length === 0"
          :colspan="headers.length"
          style="cursor: pointer; text-align: right"
          @click="openAddDialog()"
        >
        <v-btn icon class="mx-2" fab small>
                <v-icon>add</v-icon>
              </v-btn>
        </div>
        
        </template
      >
      <template
        v-if="!print && remarks.length > 0"
        v-slot:body.append="{ headers }"
      >
        <tr>
          <td
            :colspan="headers.length"
            style="cursor: pointer; text-align: center"
            @click="openAddDialog()"
          >
            add remark
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar
          flat
          :class="!alternatelayout ? 'mksborderradius' : ''"
          :style="bgcolor"
        >
          <v-toolbar-title>{{
            tabletitle + " (" + remarks.length + ")"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!print"
            icon
            class="mx-2"
            fab
            small
            @click="openAddDialog()"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item="props">
        <tr>
          <template v-for="(header, index) of headers">
            <td v-if="index < 3" :key="index">
              {{ props.item[header.value] }}
            </td>
          </template>
          <td v-if="!print" style="cursor: pointer; text-align: center">
            <v-menu
              v-if="props.item['deletable']"
              v-model="props.item.showMenu"
              offset-y
              style="max-width: 600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-icon v-bind="attrs" v-on="on">
                  <v-icon
                    small
                    @click="props.item.showMenu = !props.item.showMenu"
                    >delete</v-icon
                  >
                </v-list-item-icon>
              </template>

              <v-list style="cursor: pointer" dense>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>cancel</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>cancel</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteRemark(props.item)">
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>delete remark</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="addDialog" width="500">
      <v-card>
        <v-card-title> Add Remark </v-card-title>

        <v-card-text>
          <v-textarea
            ref="newRemarkTB"
            style="padding-top: 16px"
            auto-grow
            rows="2"
            :outlined="!alternatelayout"
            :dense="!alternatelayout"
            clearable
            v-model="newRemark"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-tooltip top open-delay="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :dark="$vuetify.theme.dark"
                color="warning"
                class="mr-2"
                @click="
                  addDialog = false;
                  newRemark = '';
                "
              >
                <v-icon v-if="IconButtons" left>cancel</v-icon>
                <span v-else>cancel</span></v-btn
              ></template
            >
            <span>Cancel dialog and Close</span>
          </v-tooltip>

          <v-spacer></v-spacer>
          <v-tooltip top open-delay="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :dark="$vuetify.theme.dark"
                color="primary"
                class="mr-2"
                @click="saveNewRemark"
              >
                <v-icon v-if="IconButtons" left>add</v-icon>
                <span v-else>add</span></v-btn
              ></template
            >
            <span>add new remark</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
tr {
  vertical-align: top;
}

td {
  padding-top: 0.5em !important;
}
</style>

<script>
export default {
  name: "mksremarks",

  props: {
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
    className: {
      type: String,
      required: true,
      default: "",
    },
    bgcolor: {
      type: String,
      required: true,
      default: "",
    },
    print: {
      type: Boolean,
      required: true,
      default: false,
    },
    IconButtons: {
      type: Boolean,
      required: true,
      default: false,
    },
    itemrights: { type: Object, required: true, default: () => ({}) },
  },

  data: () => ({
    dialog: false,
    details: false,
    headers: [
      { text: "Created", value: "created" },
      { text: "Creator", value: "Creator" },
      { text: "Remark", value: "Remark" },
      { text: "", value: "delete", sortable: false },
    ],
    remarks: [],
    loading: false,
    visible: false,
    tabletitle: "Remarks",
    newRemark: "",
    addDialog: false,
  }),
  mounted() {
    this.getdata();
  },
  watch: {
    editquery: {
      handler() {
        this.getdata();
      },
      deep: true,
    },
  },

  computed: {
    tableHeight() {
      var calcHeight = this.remarks.length * 47 + 100;
      if (calcHeight > 250) {
        calcHeight = 250;
      }
      return calcHeight + "px";
    },
  },

  methods: {
    getdata(newRemark = null) {
      this.$http
        .post("api/getRemarksForDetail", {
          query: this.editquery,
          newRemark: newRemark,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            if (response.data === "NO REMARKS CONFIGURED") {
              this.visible = false;
              return;
            } else {
              throw "malformatted JSON Data";
            }
          }

          if (this.print) {
            this.headers.forEach((h) => {
              h.sortable = false;
            });
          }

          if (response.data.error) {
            this.visible = true;
            this.tabletitle = "Error loading Data";

            this.loading = false;
          } else {
            this.remarks = response.data.data;

            if (this.print && this.remarks.length === 0) {
              this.visible = false;
            } else {
              this.visible = true;
            }
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
        });
    },

    deleteRemark(remark) {
      this.$http
        .post("api/deleteRemark", {
          query: this.editquery,
          delRemark: remark.ID_Global_Remarks,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            if (response.data === "NO REMARKS CONFIGURED") {
              this.visible = false;
              return;
            } else {
              throw "malformatted JSON Data";
            }
          }

          if (this.print) {
            this.headers.forEach((h) => {
              h.sortable = false;
            });
          }

          this.visible = true;

          if (response.data.error) {
            this.tabletitle = "Error loading Data";

            this.loading = false;
          } else {
            this.remarks = response.data.data;
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
        });
    },

    openAddDialog() {
      this.addDialog = true;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.newRemarkTB.$refs.input.focus();
        });
      });
    },

    saveNewRemark() {
      if (this.newRemark !== "") {
        this.getdata(this.newRemark);
        this.addDialog = false;
      }
    },
  },
};
</script>